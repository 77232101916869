.climate-reports{
    background-color: $background-gray;
    &__wrapper{
        padding: 40px 20px;
        @include mediaQuery(1024px){
            max-width: 1082px;
            margin: 0 auto;
            padding: 150px 20px 154px 20px;
        }
    }
    &__heading{
        margin-bottom: 20px;
        text-align: center;
        font-size: 28px;
        line-height: 42px;
        font-weight: 600;
        @include mediaQuery(1024px){
            font-size: 50px;
            line-height: 75px;
        }
    }
    &__subheading{
        margin-bottom: 40px;
        text-align: center;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        @include mediaQuery(1024px){
            max-width: 948px;
            margin: 0 auto;
            margin-bottom: 5px;
            font-size: 20px;
            line-height: 30px;
        }
    }
    &__card{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #AAAAAA;
        &--title{
            display: flex;
            align-items: center;
            h3{
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                margin-left: 15px;
                @include mediaQuery(768px){
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
        &--button{
            margin-left: auto;
        }
    }
    &__filter{
        max-width: 743px;
        display: flex;
        align-items: center;
        @include mediaQuery(425px){
            flex-direction: row;
        }
    }
    .select-menu{
        max-width: 330px;
        margin: 0px;
        text-align: center;
        @include mediaQuery(425px){
            margin: 20px;
            text-align: unset;
        }
        @include mediaQuery(768px){
            margin: 50px auto;
        }
        .select-btn{
            display: flex;
            height: 50px;
            background: #fff;
            padding: 20px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 600;
            border-radius: 25.5px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 2;
            position: relative;
            @include mediaQuery(768px){
                width: 360px;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .options{
            display: none;
            position: absolute;
            overflow-y: auto;
            padding: 0;
            margin-top: -30px;
            border-radius: 8px;
            background: #E7E6E6;
            animation-name: fadeInDown;
            -webkit-animation-name: fadeInDown;
            animation-duration: 0.35s;
            animation-fill-mode: both;
            -webkit-animation-duration: 0.35s;
            -webkit-animation-fill-mode: both;
            z-index: 1;
            @include mediaQuery(425px){
                padding: 10px;
            }
            @include mediaQuery(768px){
                width: 360px;
                font-size: 16px;
                line-height: 24px;
            }
            :first-child{
                margin-top: 20px;
            }
            .option{
                display: flex;
                height: 55px;
                cursor: pointer;
                padding: 0 16px;
                border-radius: 8px;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                line-height: 18px;
                @include mediaQuery(768px){
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    .select-menu.active {
        .options {
            display: block;
            opacity: 0;
            animation-name: fadeInUp;
            -webkit-animation-name: fadeInUp;
            animation-duration: 0.4s;
            animation-fill-mode: both;
            -webkit-animation-duration: 0.4s;
            -webkit-animation-fill-mode: both;
        }
    }
    .select-menu-year{
        max-width: 330px;
        margin: 10px;
        text-align: center;
        @include mediaQuery(425px){
            text-align: unset;
        }
        @include mediaQuery(768px){
            margin: 50px auto 50px 0px;
        }
        .select-btn-year{
            display: flex;
            height: 50px;
            background: #fff;
            padding: 20px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 600;
            border-radius: 25.5px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            z-index: 2;
            position: relative;
            @include mediaQuery(768px){
                width: 130px;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .options-year{
            display: none;
            position: absolute;
            overflow-y: auto;
            padding: 10px;
            margin-top: -30px;
            border-radius: 8px;
            background: #E7E6E6;
            animation-name: fadeInDown;
            -webkit-animation-name: fadeInDown;
            animation-duration: 0.35s;
            animation-fill-mode: both;
            -webkit-animation-duration: 0.35s;
            -webkit-animation-fill-mode: both;
            z-index: 1;
            @include mediaQuery(768px){
                width: 130px;
                font-size: 16px;
                line-height: 24px;
            }
            :first-child{
                margin-top: 20px;
            }
            .option-year{
                display: flex;
                height: 55px;
                cursor: pointer;
                padding: 0 16px;
                border-radius: 8px;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                line-height: 18px;
                @include mediaQuery(768px){
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    .select-menu-year.active{
        .options-year {
            display: block;
            opacity: 0;
            animation-name: fadeInUp;
            -webkit-animation-name: fadeInUp;
            animation-duration: 0.4s;
            animation-fill-mode: both;
            -webkit-animation-duration: 0.4s;
            -webkit-animation-fill-mode: both;
          }
    }
    .empty__info-wrapper{
        display: none;
    }
    .empty__info-quote{
        display: block;
        text-align: center;
        margin-top: 50px;
        font-size: 18px;
        padding-right: 0;
        @include mediaQuery(768px){
            font-size: 30px;
        }
    }
    .sBtn-text{
        span{
            padding-right: 5px;
            @include mediaQuery(425px){
                padding-right: 15px;
            }
        }
    }
    .sBtn-text-year{
        span{
            padding-right: 3px;
            @include mediaQuery(425px){
                padding-right: 15px;
            }
        }
    }
}
   @keyframes fadeInUp {
    from {
      transform: translate3d(0, 30px, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes fadeInDown {
    from {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    to {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }
  }