/*
* Mixins
*/

@mixin mediaQuery($breakpoint) {
    @media only screen and (min-width: $breakpoint) {
        @content;
    }
}

@mixin maxMediaQuery($breakpoint) {
    @media only screen and (max-width: $breakpoint) {
        @content;
    }
}