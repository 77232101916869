.news-section {
  overflow-x: hidden;
  padding: 150px 0 30px;
  @include mediaQuery(768px) {
    padding: 60px 0;
  }
  &__heading {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
    @include mediaQuery(1024px) {
      font-size: 50px;
      margin-bottom: 60px;
    }
  }
  &__slider {
    position: relative;
    .slider {
      article {
        padding: 0 15px;
        @include mediaQuery(768px) {
          padding: 0 25px;
        }
        @include mediaQuery(1024px) {
          padding: 0 50px;
        }

        a {
          text-decoration: none;
        }

        figure {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0;
          padding: 0;
          max-height: 270px;
          border-radius: 45px;
          overflow: hidden;
          margin-bottom: 2em;
          @include mediaQuery(768px) {
            border-radius: 75px;
          }
          img {
            width: 100%;
            height: auto;
            &.badge {
              position: absolute;
              top: 0;
              left: 50%;
              max-width: 80px;
              transform: translateX(-50%);
              @include mediaQuery(1024px) {
                max-width: 200px;
                width: auto;
                height: auto;
              }
            }
          }
        }
        p {
          line-height: 1.5;
          text-align: center;
          @include mediaQuery(768px) {
            text-align: unset;
          }
          .blue {
            color: $color-primary !important;
          }
        }
      }

      .slick-list {
        overflow: visible;
      }

      .slick-slide {
        transition: all 0.5s;
      }

      .slick-slide:not(.slick-current) {
        opacity: 0.4;
      }

      .slick-next,
      .slick-prev {
        top: 32% !important;
        z-index: 100;
      }
    }
    .controlls {
      position: absolute;
      display: flex;
      width: calc(100% - 80px);
      margin: 0 40px;
      justify-content: space-between;
      align-items: center;
      top: -210px;
      .slick-arrow {
        background-image: url(/wp-content/themes/g1/assets/images/climate/icons/icon-arrow-mobile.svg);
        height: 36px;
        width: 36px;
        transition: all 0.25s ease-in-out;
        z-index: 100;
        cursor: pointer;
        &.prev {
          transform: rotate(180deg);
        }
      }
      .slide-icon {
        display: block;
        img {
          width: 150px;
          height: auto;
        }
      }
    }

    @include mediaQuery(768px) {
      .controlls {
        position: relative;
        top: 0;
        .slick-arrow {
          position: absolute;
          top: 105px;
          height: 13px;
          width: 13px;
          border-color: $color-secondary;
          border-style: solid;
          border-width: 0 3px 3px 0;
          transition: all 0.25s ease-in-out;
          margin: 40px 0 0 0;
          z-index: 100;
          cursor: pointer;
          &.next {
            right: 25% !important;
            transform: rotate(315deg);
          }
          &.prev {
            left: 25% !important;
            transform: rotate(135deg);
          }
        }
        .slide-icon {
          display: none;
        }
      }
    }
  }
}
