.g1-climate .emission {
  padding: 50px 20px;
  padding-top: 100px;
  margin-top: -30px;
  .scopes {
    .scope {
      width: auto !important;
      &::after {
        height: calc(100% + 150px) !important;
      }
    }
  }
  @include maxMediaQuery(768px) {
    padding-top: 170px;
    margin-top: -170px;
  }
}
.emission ul {
  margin-top: 0px !important;
}
.emission-controllers {
  display: none;
}
@include maxMediaQuery(1024px) {
  .emission-controllers {
    width: calc(100% - 80px);
    margin: 0 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-arrow {
      background-image: url(/wp-content/themes/g1/assets/images/climate/icons/icon-arrow-mobile.svg);
      height: 36px;
      width: 36px;
      transition: all 0.25s ease-in-out;
      z-index: 100;
      cursor: pointer;
      &-prev {
        transform: rotate(180deg);
      }
    }
    .slide-icon {
      img {
        width: 150px;
        height: auto;
      }
    }
  }
  .emission {
    overflow-x: scroll !important;
    scroll-behavior: smooth;

    .air-composition,
    .scopes,
    .scope-below {
      flex-wrap: nowrap !important;
      min-width: 1024px !important;
    }
    .scopes {
      .scope {
        width: auto !important;
      }
    }
    .scope-below {
      .scope-below-item {
        span {
          border-radius: 75px !important;
          flex-direction: row !important;
          height: 100% !important;
          min-height: 80px !important;
          &::after {
            transform: rotate(315deg) !important;
          }
        }
        &:nth-of-type(1) {
          span {
            width: calc(100% + 35px) !important;
          }
        }
        &:nth-of-type(2) {
          span {
            width: calc(100% + 70px) !important;
            left: -35px !important;
          }
        }
        &:nth-of-type(3) {
          span {
            width: calc(100% + 35px) !important;
            left: -35px !important;
            padding-left: 70px !important;
          }
        }
      }
    }
  }
}

.g1-climate {
  .emission {
    .scopes {
      .scope {
        &:nth-of-type(3) {
          &:before {
            border-color: $color-secondary !important;
          }
          &:after {
            background: $color-secondary !important;
          }
        }
        &:nth-of-type(4),
        &:nth-of-type(2) {
          &:before {
            border-color: $color-primary !important;
          }
          &:after {
            background: $color-primary !important;
          }
        }
      }
    }
    .scope-below {
      margin-bottom: 20px;
      overflow: hidden;
      border-radius: 15px !important;
      justify-content: flex-start !important;
      &-item {
        &.w50 {
          width: calc(50% - 60px) !important;
        }
        &.w25 {
          width: calc(25%) !important;
        }
        span {
          position: relative;
          border-radius: 15px !important;
          padding-right: 10px !important;
          padding-left: 80px !important;
          padding-right: 0 !important;
          &::after {
            display: none !important;
          }
          &::before {
            display: block;
            content: '';
            position: absolute;
            right: 0px;
            top: -61px;
            width: 200px;
            height: 200px;
            transform: rotate(322deg) skewX(15deg);
            border-radius: 15px;
            background-color: $color-primary;
            z-index: -2;
            @include maxMediaQuery(1024px) {
              top: -53px;
            }
          }
        }
        &:nth-of-type(3) {
          span {
            padding-left: 140px !important;
          }
        }
        &:nth-of-type(1),
        &:nth-of-type(3) {
          span {
            background: $color-primary !important;
          }
        }
        &:nth-of-type(2) {
          span {
            background: $color-secondary !important;
            &::before {
              background: $color-secondary !important;
            }
          }
        }
      }
    }
  }
}
