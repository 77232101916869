.carbon-reduce{
    background-image: url('/wp-content/themes/g1/assets/images/bgreducebig.png');
    background-position: bottom;
    &__wrapper{
        padding: 20px;
        @include mediaQuery(1024px){
            max-width: 1128px;
            margin: 0 auto;
            padding: 130px 20px 292px 20px;
        }
        :nth-child(4){
            margin-bottom: 30px;
        }
    }
    &__heading{
        text-align: center;
        font-size: 28px;
        line-height: 42px;
        font-weight: 600;
        max-width: 260px;
        margin: 40px auto;
        @include mediaQuery(768px){
            font-size: 28px;
            line-height: 75px;
            max-width: 500px;
            margin: 0 auto 60px auto;
        }
        @include mediaQuery(1024px){
            font-size: 50px;
            line-height: 75px;
            max-width: 700px;
            margin: 0 auto 130px auto;
        }
    }
    &__card{
        text-align: center;
        margin-bottom: 40px;
        margin: 0 30px;
        @include mediaQuery(1024px){
            display: flex;
            align-items: self-start;
            margin-bottom: 93px;
        }
        ul{
            margin: 0;
            padding: 0;
            @include mediaQuery(768px){
              padding: 0 0 0 40px;
            }
        }
        .scope{
            display: inline-block;
            padding: 5px 25px;
            border-radius: 16.5px;
            background: $color-secondary;
            color: white;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            margin-bottom: 10px;
            @include mediaQuery(1024px){
                min-width: 125px;
                margin-bottom: 0;
            }
        }
        .gray_scope{
            background: $dark-gray;
        }
        .blue_scope{
            background: $color-primary;
        }
        &--quote{
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            text-align: center;
            color: $color-dark;
            margin-bottom: 20px;
            @include mediaQuery(1024px){
                font-size: 20px;
                line-height: 30px;
                text-align: start;
                margin-left: 35px;
            }
        }
        &--add{
            max-width: 700px;
            margin: 0 auto;
            &-small{
                text-align: center;
                font-size: 10px;
                line-height: 21px;
                font-weight: 400;
                list-style-type: none;
                @include mediaQuery(1024px){
                    font-size: 14px;
                    text-align: start;
                    margin-left: 50px;
                    list-style-type: disc;
                }
            }
        }
    }
    .reduce_blue{
        color: $color-primary;
    }
    .addon{
        font-weight: 700;
    }
}