.g1-climate .carbon-footprint-tabs {
  flex-wrap: wrap !important;
  flex-direction: row;
  gap: 20px 40px;
  @include mediaQuery(768px) {
    gap: 0px 40px;
  }
  @include mediaQuery(561px) {
    .chart-circle {
      align-items: center !important;
      &-desc-left {
        flex-direction: row-reverse !important;
        @include mediaQuery(1024px) {
          flex-direction: column !important;
        }
        @include mediaQuery(1300px) {
          flex-direction: row-reverse !important;
        }
        .info-tooltip {
          order: 3 !important;
          @include mediaQuery(1024px) {
            order: 1 !important;
          }
        }
        span {
          &:nth-of-type(2) {
            order: 2 !important;
            @include mediaQuery(1024px) {
              order: 3 !important;
            }
          }
          &:nth-of-type(3) {
            order: 1 !important;
            @include mediaQuery(1024px) {
              order: 3 !important;
            }
          }
        }
      }
    }
  }
  @include maxMediaQuery(840px) {
    .chart-circle {
      &-picture {
        order: 4 !important;
        min-height: 300px !important;
      }
    }
  }
  @include maxMediaQuery(561px) {
    .chart-circle {
      align-items: center !important;
      &-desc-top,
      &-desc-bottom,
      &-desc-left,
      &-desc-right {
        flex-direction: row !important;
        white-space: nowrap;
        flex-wrap: wrap;
        justify-content: center;
        margin: 10px 0px !important;
        gap: 0px 10px;
      }
      .info-tooltip {
        order: 2 !important;
      }
      span {
        &:nth-of-type(1) {
          order: 1 !important;
        }
        &:nth-of-type(2) {
          order: 3 !important;
        }
        &:nth-of-type(3) {
          order: 4 !important;
        }
      }
    }
    .chart-bar {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      overflow-y: scroll;
      &-row {
        flex-direction: column-reverse;
        min-height: 350px;
        gap: 10px;
        .city {
          width: auto !important;
          margin-right: 0px !important;
        }
        .bar {
          width: 25px !important;
          &-width {
            display: none;
          }
        }
        .percent {
          margin-left: 0px !important;
        }
        .value {
          display: none;
        }
      }
    }
  }
  .chart-circle {
    &-desc-top,
    &-desc-bottom,
    &-desc-left,
    &-desc-right {
      &::before {
        display: none !important;
      }
    }
    &-desc-right {
      right: 0px !important;
    }
    &-desc-left {
      left: 0px !important;
    }
  }
}
.filters-wrapper {
  display: flex;
  gap: 40px;
}

.disabled {
  display: none !important;
}

.year-select {
  position: relative;
}

.year-select select {
  display: none;
}

.select-selected {
  background-color: #fff;
  border-radius: 25px;
  padding: 10px;
  display: flex;
  gap: 10px;
  font-weight: 600;
  &:after {
    position: relative;
    display: block;
    content: url('/wp-content/themes/g1/assets/images/arrow_reports.png');
    transition: transform 0.5s;
  }
  &.select-arrow-active {
    &:after {
      border-color: transparent transparent #fff transparent;
      transform: rotate(180deg);
    }
  }
}

.select-items div,
.select-selected {
  color: #000;
  padding: 12px 35px;
  cursor: pointer;
}

.select-selected {
  position: relative;
  z-index: 10;
}

.select-items {
  position: absolute;
  background-color: #fff;
  top: 25px;
  left: 0;
  right: 0;
  z-index: 9;
  &::before {
    display: block;
    position: relative;
    content: '';
    background-color: #fff;
    width: 100%;
    height: 25px;
    z-index: -10;
  }
}

.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.chart-circle-desc {
  .name {
    color: #fff !important;
  }
  &-top {
    .name {
      background-color: $color-secondary;
    }
  }
  &-left {
    .name {
      background-color: #949494;
    }
  }
  &-right {
    .name {
      background-color: $color-primary;
    }
  }
  &-bottom {
    .name {
      background-color: #000;
    }
  }
}

.chart-circle-picture {
  img {
    position: absolute;
    max-width: 30%;
    max-height: 30%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
