.emission {
    .scope-below {
        .scroll-down {
            margin: 0 auto!important;
            @include mediaQuery(1024px) {
                right: 50%!important; 
                margin: 20px auto 60px!important;
            }
        }
    }
}

.carbon-footprint {
    &-inner {
        margin-top: 20px!important;
    }
}