.footprint-years{
    margin-top: 30px;
    @include mediaQuery(1024px){
        margin-top: 55px;
    }
    &__wrapper{
        padding: 10px 20px;
        @include mediaQuery(600px){
            padding: 10px 20px;
        }
        @include mediaQuery(1024px){
            max-width: 1082px;
            margin: 0 auto;
            padding: 15px 20px 80px 20px;
        }
    }
    &__heading{
        text-align: center;
        font-size: 28px;
        line-height: 42px;
        font-weight: 600;
        max-width: 260px;
        margin: 40px auto;
        @include mediaQuery(768px){
            font-size: 28px;
            line-height: 75px;
            max-width: 500px;
        }
        @include mediaQuery(1024px){
            font-size: 50px;
            line-height: 75px;
            max-width: 700px;
        }
    }
    &__links{
        text-align: center;
        background-color: #F3F3F3;
        max-width: 455px;
        margin: 0 auto 50px auto;
        border-radius: 25.5px;
        .tablinks{
            border-radius: 25.5px;
            border: none;
            color: #aaa;
            background-color: #F3F3F3;
            font-size: 12px;
            line-height: 18px;
            font-weight: 600;
            cursor: pointer;
            @include mediaQuery(375px){
                padding: 12px 15px;
            }
            @include mediaQuery(425px){
                padding: 12px 20px;
            }
            @include mediaQuery(600px){
                padding: 12px 20px;
                font-size: 16px;
                line-height: 24px;
            }
        }
        [data-id = "footprint-years-scope-total"].active{
            background-color: $color-dark;
        }
        [data-id = "footprint-years-scope-one"].active{
            background-color: $color-secondary;
        }
        [data-id = "footprint-years-scope-two"].active{
            background-color: $dark-gray;
        }
        [data-id = "footprint-years-scope-three"].active{
            background-color: $color-primary;
        }
        .active{
            padding: 12px 20px;
            color: white;
            @include mediaQuery(375px){
                padding: 12px 25px;
            }
            @include mediaQuery(425px){
                padding: 12px 35px;
            }
            @include mediaQuery(600px){
                padding: 12px 40px;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    .reduce_blue{
        color: $color-primary;
    }
    .tabcontent-wrapper{
        .tabcontent{
            display: none;
            width: 100%;
            height: 350px;
            @include mediaQuery(768px){
                min-height: 521px;
                height: 100%;
            }
        }
        .active{
            display: block;
        }
    }
}