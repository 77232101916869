.top-hero {
    &-section {
        margin: 0 auto;
        padding: 80px 0 0 0;
        @include mediaQuery(1024px) {
            padding: 100px 0px;
        }
        &__inner {
            overflow-x: hidden;
            margin: 70px auto 0 auto;
            max-width: 1570px;
            padding: 60px 20px;
            @include mediaQuery(768px) {
                margin: 0 auto;
            }
            @include mediaQuery(1024px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                padding: 130px 20px;
            }
        }
        &__text {
            padding-bottom: 50px;
            &--heading {
                h1 {
                    line-height: 1.4;
                    font-size: 32px;
                @include mediaQuery(1024px) {
                    font-size: 48px;
                }
                }
                span {
                    color: $color-primary;
                }
                em {
                    font-weight: 200;
                }
            }
            &--description {
                line-height: 1.4;
                font-size: 16px;
                @include mediaQuery(1024px) {
                    font-size: 18px;
                }
            }
            .arrow {
                height: 13px;
                width: 13px;
                border-color: $color-secondary;
                border-style: solid;
                border-width: 0 2px 2px 0;
                display: block;
                transition: all .25s ease-in-out;
                transform: rotate(45deg);
                margin: 40px 0 0 0;
                
            }
        }
        &__image {
            display: flex;
            justify-content: center;
            @include mediaQuery(1024px) {
                display: flex;
                justify-content: end;
            }
            figure {
                margin: 0!important;
                img {
                    border-radius: 25%;
                }
            }
        
            
        }
    }
}