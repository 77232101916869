.climate-nav {
  padding: 20px 0;
  background-color: #f4f4f4;
  ul {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    justify-content: center;
    text-align: center;
    list-style: none;
    gap: 10px 0px;
    margin: 0;
    padding: 0;
    font-size: 14px;
    @include mediaQuery(768px) {
      gap: 40px;
      font-size: 16px;
    }

    a {
      text-decoration: none;
      color: #616161;
      display: inline-block;
      padding: 0px 15px;
    }
  }
}
