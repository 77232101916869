/*!
Theme Name: Groupone - Climate subpage
Theme URI:
Author: 
Author URI: 
Description:
Version: 1.0.0
License:
License URI:
Text Domain: groupone
Tags:
*/
/*
* Mixins
*/
/*
 * Fonts
 */
/*
 * Dimensions
 */
/*
 * Colors
 */
/*
 * Transitions
 */
/* line 1, assets/scss/sections/_climate-nav.scss */
.climate-nav {
  padding: 20px 0;
  background-color: #f4f4f4; }
  /* line 4, assets/scss/sections/_climate-nav.scss */
  .climate-nav ul {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    justify-content: center;
    text-align: center;
    list-style: none;
    gap: 10px 0px;
    margin: 0;
    padding: 0;
    font-size: 14px; }
    @media only screen and (min-width: 768px) {
      /* line 4, assets/scss/sections/_climate-nav.scss */
      .climate-nav ul {
        gap: 40px;
        font-size: 16px; } }
    /* line 20, assets/scss/sections/_climate-nav.scss */
    .climate-nav ul a {
      text-decoration: none;
      color: #616161;
      display: inline-block;
      padding: 0px 15px; }

/* line 2, assets/scss/sections/_climate-top-hero.scss */
.top-hero-section {
  margin: 0 auto;
  padding: 80px 0 0 0; }
  @media only screen and (min-width: 1024px) {
    /* line 2, assets/scss/sections/_climate-top-hero.scss */
    .top-hero-section {
      padding: 100px 0px; } }
  /* line 8, assets/scss/sections/_climate-top-hero.scss */
  .top-hero-section__inner {
    overflow-x: hidden;
    margin: 70px auto 0 auto;
    max-width: 1570px;
    padding: 60px 20px; }
    @media only screen and (min-width: 768px) {
      /* line 8, assets/scss/sections/_climate-top-hero.scss */
      .top-hero-section__inner {
        margin: 0 auto; } }
    @media only screen and (min-width: 1024px) {
      /* line 8, assets/scss/sections/_climate-top-hero.scss */
      .top-hero-section__inner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        padding: 130px 20px; } }
  /* line 23, assets/scss/sections/_climate-top-hero.scss */
  .top-hero-section__text {
    padding-bottom: 50px; }
    /* line 26, assets/scss/sections/_climate-top-hero.scss */
    .top-hero-section__text--heading h1 {
      line-height: 1.4;
      font-size: 32px; }
      @media only screen and (min-width: 1024px) {
        /* line 26, assets/scss/sections/_climate-top-hero.scss */
        .top-hero-section__text--heading h1 {
          font-size: 48px; } }
    /* line 33, assets/scss/sections/_climate-top-hero.scss */
    .top-hero-section__text--heading span {
      color: #47A2D6; }
    /* line 36, assets/scss/sections/_climate-top-hero.scss */
    .top-hero-section__text--heading em {
      font-weight: 200; }
    /* line 40, assets/scss/sections/_climate-top-hero.scss */
    .top-hero-section__text--description {
      line-height: 1.4;
      font-size: 16px; }
      @media only screen and (min-width: 1024px) {
        /* line 40, assets/scss/sections/_climate-top-hero.scss */
        .top-hero-section__text--description {
          font-size: 18px; } }
    /* line 47, assets/scss/sections/_climate-top-hero.scss */
    .top-hero-section__text .arrow {
      height: 13px;
      width: 13px;
      border-color: #DB618D;
      border-style: solid;
      border-width: 0 2px 2px 0;
      display: block;
      transition: all .25s ease-in-out;
      transform: rotate(45deg);
      margin: 40px 0 0 0; }
  /* line 60, assets/scss/sections/_climate-top-hero.scss */
  .top-hero-section__image {
    display: flex;
    justify-content: center; }
    @media only screen and (min-width: 1024px) {
      /* line 60, assets/scss/sections/_climate-top-hero.scss */
      .top-hero-section__image {
        display: flex;
        justify-content: end; } }
    /* line 67, assets/scss/sections/_climate-top-hero.scss */
    .top-hero-section__image figure {
      margin: 0 !important; }
      /* line 69, assets/scss/sections/_climate-top-hero.scss */
      .top-hero-section__image figure img {
        border-radius: 25%; }

/* line 4, assets/scss/sections/_climate-hero.scss */
.hero-section__inner h1 a {
  text-decoration: none;
  color: #fff; }

/* line 9, assets/scss/sections/_climate-hero.scss */
.hero-section__inner .info-tooltip {
  margin-top: 20px !important; }
  @media only screen and (min-width: 768px) {
    /* line 9, assets/scss/sections/_climate-hero.scss */
    .hero-section__inner .info-tooltip {
      margin-top: 70px !important; } }

/* line 15, assets/scss/sections/_climate-hero.scss */
.hero-section__inner .tooltip-desc {
  font-size: 20px;
  line-height: 1.5; }

/* line 19, assets/scss/sections/_climate-hero.scss */
.hero-section__inner .tooltip-dot {
  bottom: -80px !important; }
  @media only screen and (min-width: 768px) {
    /* line 19, assets/scss/sections/_climate-hero.scss */
    .hero-section__inner .tooltip-dot {
      bottom: -110px !important; } }

/* line 1, assets/scss/sections/_climate-emission.scss */
.g1-climate .emission {
  padding: 50px 20px;
  padding-top: 100px;
  margin-top: -30px; }
  /* line 6, assets/scss/sections/_climate-emission.scss */
  .g1-climate .emission .scopes .scope {
    width: auto !important; }
    /* line 8, assets/scss/sections/_climate-emission.scss */
    .g1-climate .emission .scopes .scope::after {
      height: calc(100% + 150px) !important; }
  @media only screen and (max-width: 768px) {
    /* line 1, assets/scss/sections/_climate-emission.scss */
    .g1-climate .emission {
      padding-top: 170px;
      margin-top: -170px; } }

/* line 18, assets/scss/sections/_climate-emission.scss */
.emission ul {
  margin-top: 0px !important; }

/* line 21, assets/scss/sections/_climate-emission.scss */
.emission-controllers {
  display: none; }

@media only screen and (max-width: 1024px) {
  /* line 25, assets/scss/sections/_climate-emission.scss */
  .emission-controllers {
    width: calc(100% - 80px);
    margin: 0 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    /* line 32, assets/scss/sections/_climate-emission.scss */
    .emission-controllers-arrow {
      background-image: url(/wp-content/themes/g1/assets/images/climate/icons/icon-arrow-mobile.svg);
      height: 36px;
      width: 36px;
      transition: all 0.25s ease-in-out;
      z-index: 100;
      cursor: pointer; }
      /* line 39, assets/scss/sections/_climate-emission.scss */
      .emission-controllers-arrow-prev {
        transform: rotate(180deg); }
    /* line 44, assets/scss/sections/_climate-emission.scss */
    .emission-controllers .slide-icon img {
      width: 150px;
      height: auto; }
  /* line 50, assets/scss/sections/_climate-emission.scss */
  .emission {
    overflow-x: scroll !important;
    scroll-behavior: smooth; }
    /* line 54, assets/scss/sections/_climate-emission.scss */
    .emission .air-composition,
    .emission .scopes,
    .emission .scope-below {
      flex-wrap: nowrap !important;
      min-width: 1024px !important; }
    /* line 61, assets/scss/sections/_climate-emission.scss */
    .emission .scopes .scope {
      width: auto !important; }
    /* line 67, assets/scss/sections/_climate-emission.scss */
    .emission .scope-below .scope-below-item span {
      border-radius: 75px !important;
      flex-direction: row !important;
      height: 100% !important;
      min-height: 80px !important; }
      /* line 72, assets/scss/sections/_climate-emission.scss */
      .emission .scope-below .scope-below-item span::after {
        transform: rotate(315deg) !important; }
    /* line 77, assets/scss/sections/_climate-emission.scss */
    .emission .scope-below .scope-below-item:nth-of-type(1) span {
      width: calc(100% + 35px) !important; }
    /* line 82, assets/scss/sections/_climate-emission.scss */
    .emission .scope-below .scope-below-item:nth-of-type(2) span {
      width: calc(100% + 70px) !important;
      left: -35px !important; }
    /* line 88, assets/scss/sections/_climate-emission.scss */
    .emission .scope-below .scope-below-item:nth-of-type(3) span {
      width: calc(100% + 35px) !important;
      left: -35px !important;
      padding-left: 70px !important; } }

/* line 104, assets/scss/sections/_climate-emission.scss */
.g1-climate .emission .scopes .scope:nth-of-type(3):before {
  border-color: #DB618D !important; }

/* line 107, assets/scss/sections/_climate-emission.scss */
.g1-climate .emission .scopes .scope:nth-of-type(3):after {
  background: #DB618D !important; }

/* line 113, assets/scss/sections/_climate-emission.scss */
.g1-climate .emission .scopes .scope:nth-of-type(4):before, .g1-climate .emission .scopes .scope:nth-of-type(2):before {
  border-color: #47A2D6 !important; }

/* line 116, assets/scss/sections/_climate-emission.scss */
.g1-climate .emission .scopes .scope:nth-of-type(4):after, .g1-climate .emission .scopes .scope:nth-of-type(2):after {
  background: #47A2D6 !important; }

/* line 122, assets/scss/sections/_climate-emission.scss */
.g1-climate .emission .scope-below {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 15px !important;
  justify-content: flex-start !important; }
  /* line 128, assets/scss/sections/_climate-emission.scss */
  .g1-climate .emission .scope-below-item.w50 {
    width: calc(50% - 60px) !important; }
  /* line 131, assets/scss/sections/_climate-emission.scss */
  .g1-climate .emission .scope-below-item.w25 {
    width: calc(25%) !important; }
  /* line 134, assets/scss/sections/_climate-emission.scss */
  .g1-climate .emission .scope-below-item span {
    position: relative;
    border-radius: 15px !important;
    padding-right: 10px !important;
    padding-left: 80px !important;
    padding-right: 0 !important; }
    /* line 140, assets/scss/sections/_climate-emission.scss */
    .g1-climate .emission .scope-below-item span::after {
      display: none !important; }
    /* line 143, assets/scss/sections/_climate-emission.scss */
    .g1-climate .emission .scope-below-item span::before {
      display: block;
      content: '';
      position: absolute;
      right: 0px;
      top: -61px;
      width: 200px;
      height: 200px;
      transform: rotate(322deg) skewX(15deg);
      border-radius: 15px;
      background-color: #47A2D6;
      z-index: -2; }
      @media only screen and (max-width: 1024px) {
        /* line 143, assets/scss/sections/_climate-emission.scss */
        .g1-climate .emission .scope-below-item span::before {
          top: -53px; } }
  /* line 161, assets/scss/sections/_climate-emission.scss */
  .g1-climate .emission .scope-below-item:nth-of-type(3) span {
    padding-left: 140px !important; }
  /* line 167, assets/scss/sections/_climate-emission.scss */
  .g1-climate .emission .scope-below-item:nth-of-type(1) span, .g1-climate .emission .scope-below-item:nth-of-type(3) span {
    background: #47A2D6 !important; }
  /* line 172, assets/scss/sections/_climate-emission.scss */
  .g1-climate .emission .scope-below-item:nth-of-type(2) span {
    background: #DB618D !important; }
    /* line 174, assets/scss/sections/_climate-emission.scss */
    .g1-climate .emission .scope-below-item:nth-of-type(2) span::before {
      background: #DB618D !important; }

/* line 1, assets/scss/sections/_climate-carbon-reduce.scss */
.carbon-reduce {
  background-image: url("/wp-content/themes/g1/assets/images/bgreducebig.png");
  background-position: bottom; }
  /* line 4, assets/scss/sections/_climate-carbon-reduce.scss */
  .carbon-reduce__wrapper {
    padding: 20px; }
    @media only screen and (min-width: 1024px) {
      /* line 4, assets/scss/sections/_climate-carbon-reduce.scss */
      .carbon-reduce__wrapper {
        max-width: 1128px;
        margin: 0 auto;
        padding: 130px 20px 292px 20px; } }
    /* line 11, assets/scss/sections/_climate-carbon-reduce.scss */
    .carbon-reduce__wrapper :nth-child(4) {
      margin-bottom: 30px; }
  /* line 15, assets/scss/sections/_climate-carbon-reduce.scss */
  .carbon-reduce__heading {
    text-align: center;
    font-size: 28px;
    line-height: 42px;
    font-weight: 600;
    max-width: 260px;
    margin: 40px auto; }
    @media only screen and (min-width: 768px) {
      /* line 15, assets/scss/sections/_climate-carbon-reduce.scss */
      .carbon-reduce__heading {
        font-size: 28px;
        line-height: 75px;
        max-width: 500px;
        margin: 0 auto 60px auto; } }
    @media only screen and (min-width: 1024px) {
      /* line 15, assets/scss/sections/_climate-carbon-reduce.scss */
      .carbon-reduce__heading {
        font-size: 50px;
        line-height: 75px;
        max-width: 700px;
        margin: 0 auto 130px auto; } }
  /* line 35, assets/scss/sections/_climate-carbon-reduce.scss */
  .carbon-reduce__card {
    text-align: center;
    margin-bottom: 40px;
    margin: 0 30px; }
    @media only screen and (min-width: 1024px) {
      /* line 35, assets/scss/sections/_climate-carbon-reduce.scss */
      .carbon-reduce__card {
        display: flex;
        align-items: self-start;
        margin-bottom: 93px; } }
    /* line 44, assets/scss/sections/_climate-carbon-reduce.scss */
    .carbon-reduce__card ul {
      margin: 0;
      padding: 0; }
      @media only screen and (min-width: 768px) {
        /* line 44, assets/scss/sections/_climate-carbon-reduce.scss */
        .carbon-reduce__card ul {
          padding: 0 0 0 40px; } }
    /* line 51, assets/scss/sections/_climate-carbon-reduce.scss */
    .carbon-reduce__card .scope {
      display: inline-block;
      padding: 5px 25px;
      border-radius: 16.5px;
      background: #DB618D;
      color: white;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 10px; }
      @media only screen and (min-width: 1024px) {
        /* line 51, assets/scss/sections/_climate-carbon-reduce.scss */
        .carbon-reduce__card .scope {
          min-width: 125px;
          margin-bottom: 0; } }
    /* line 66, assets/scss/sections/_climate-carbon-reduce.scss */
    .carbon-reduce__card .gray_scope {
      background: #949494; }
    /* line 69, assets/scss/sections/_climate-carbon-reduce.scss */
    .carbon-reduce__card .blue_scope {
      background: #47A2D6; }
    /* line 72, assets/scss/sections/_climate-carbon-reduce.scss */
    .carbon-reduce__card--quote {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      text-align: center;
      color: #000;
      margin-bottom: 20px; }
      @media only screen and (min-width: 1024px) {
        /* line 72, assets/scss/sections/_climate-carbon-reduce.scss */
        .carbon-reduce__card--quote {
          font-size: 20px;
          line-height: 30px;
          text-align: start;
          margin-left: 35px; } }
    /* line 86, assets/scss/sections/_climate-carbon-reduce.scss */
    .carbon-reduce__card--add {
      max-width: 700px;
      margin: 0 auto; }
      /* line 89, assets/scss/sections/_climate-carbon-reduce.scss */
      .carbon-reduce__card--add-small {
        text-align: center;
        font-size: 10px;
        line-height: 21px;
        font-weight: 400;
        list-style-type: none; }
        @media only screen and (min-width: 1024px) {
          /* line 89, assets/scss/sections/_climate-carbon-reduce.scss */
          .carbon-reduce__card--add-small {
            font-size: 14px;
            text-align: start;
            margin-left: 50px;
            list-style-type: disc; } }
  /* line 104, assets/scss/sections/_climate-carbon-reduce.scss */
  .carbon-reduce .reduce_blue {
    color: #47A2D6; }
  /* line 107, assets/scss/sections/_climate-carbon-reduce.scss */
  .carbon-reduce .addon {
    font-weight: 700; }

/* line 1, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
.footprint-years {
  margin-top: 30px; }
  @media only screen and (min-width: 1024px) {
    /* line 1, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
    .footprint-years {
      margin-top: 55px; } }
  /* line 6, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
  .footprint-years__wrapper {
    padding: 10px 20px; }
    @media only screen and (min-width: 600px) {
      /* line 6, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
      .footprint-years__wrapper {
        padding: 10px 20px; } }
    @media only screen and (min-width: 1024px) {
      /* line 6, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
      .footprint-years__wrapper {
        max-width: 1082px;
        margin: 0 auto;
        padding: 15px 20px 80px 20px; } }
  /* line 17, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
  .footprint-years__heading {
    text-align: center;
    font-size: 28px;
    line-height: 42px;
    font-weight: 600;
    max-width: 260px;
    margin: 40px auto; }
    @media only screen and (min-width: 768px) {
      /* line 17, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
      .footprint-years__heading {
        font-size: 28px;
        line-height: 75px;
        max-width: 500px; } }
    @media only screen and (min-width: 1024px) {
      /* line 17, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
      .footprint-years__heading {
        font-size: 50px;
        line-height: 75px;
        max-width: 700px; } }
  /* line 35, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
  .footprint-years__links {
    text-align: center;
    background-color: #F3F3F3;
    max-width: 455px;
    margin: 0 auto 50px auto;
    border-radius: 25.5px; }
    /* line 41, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
    .footprint-years__links .tablinks {
      border-radius: 25.5px;
      border: none;
      color: #aaa;
      background-color: #F3F3F3;
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      cursor: pointer; }
      @media only screen and (min-width: 375px) {
        /* line 41, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
        .footprint-years__links .tablinks {
          padding: 12px 15px; } }
      @media only screen and (min-width: 425px) {
        /* line 41, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
        .footprint-years__links .tablinks {
          padding: 12px 20px; } }
      @media only screen and (min-width: 600px) {
        /* line 41, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
        .footprint-years__links .tablinks {
          padding: 12px 20px;
          font-size: 16px;
          line-height: 24px; } }
    /* line 62, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
    .footprint-years__links [data-id="footprint-years-scope-total"].active {
      background-color: #000; }
    /* line 65, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
    .footprint-years__links [data-id="footprint-years-scope-one"].active {
      background-color: #DB618D; }
    /* line 68, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
    .footprint-years__links [data-id="footprint-years-scope-two"].active {
      background-color: #949494; }
    /* line 71, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
    .footprint-years__links [data-id="footprint-years-scope-three"].active {
      background-color: #47A2D6; }
    /* line 74, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
    .footprint-years__links .active {
      padding: 12px 20px;
      color: white; }
      @media only screen and (min-width: 375px) {
        /* line 74, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
        .footprint-years__links .active {
          padding: 12px 25px; } }
      @media only screen and (min-width: 425px) {
        /* line 74, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
        .footprint-years__links .active {
          padding: 12px 35px; } }
      @media only screen and (min-width: 600px) {
        /* line 74, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
        .footprint-years__links .active {
          padding: 12px 40px;
          font-size: 16px;
          line-height: 24px; } }
  /* line 90, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
  .footprint-years .reduce_blue {
    color: #47A2D6; }
  /* line 94, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
  .footprint-years .tabcontent-wrapper .tabcontent {
    display: none;
    width: 100%;
    height: 350px; }
    @media only screen and (min-width: 768px) {
      /* line 94, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
      .footprint-years .tabcontent-wrapper .tabcontent {
        min-height: 521px;
        height: 100%; } }
  /* line 103, assets/scss/sections/_climate-carbon-footprint-over-years.scss */
  .footprint-years .tabcontent-wrapper .active {
    display: block; }

/* line 1, assets/scss/sections/_climate-news.scss */
.news-section {
  overflow-x: hidden;
  padding: 150px 0 30px; }
  @media only screen and (min-width: 768px) {
    /* line 1, assets/scss/sections/_climate-news.scss */
    .news-section {
      padding: 60px 0; } }
  /* line 7, assets/scss/sections/_climate-news.scss */
  .news-section__heading {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px; }
    @media only screen and (min-width: 1024px) {
      /* line 7, assets/scss/sections/_climate-news.scss */
      .news-section__heading {
        font-size: 50px;
        margin-bottom: 60px; } }
  /* line 17, assets/scss/sections/_climate-news.scss */
  .news-section__slider {
    position: relative; }
    /* line 20, assets/scss/sections/_climate-news.scss */
    .news-section__slider .slider article {
      padding: 0 15px; }
      @media only screen and (min-width: 768px) {
        /* line 20, assets/scss/sections/_climate-news.scss */
        .news-section__slider .slider article {
          padding: 0 25px; } }
      @media only screen and (min-width: 1024px) {
        /* line 20, assets/scss/sections/_climate-news.scss */
        .news-section__slider .slider article {
          padding: 0 50px; } }
      /* line 29, assets/scss/sections/_climate-news.scss */
      .news-section__slider .slider article a {
        text-decoration: none; }
      /* line 33, assets/scss/sections/_climate-news.scss */
      .news-section__slider .slider article figure {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        max-height: 270px;
        border-radius: 45px;
        overflow: hidden;
        margin-bottom: 2em; }
        @media only screen and (min-width: 768px) {
          /* line 33, assets/scss/sections/_climate-news.scss */
          .news-section__slider .slider article figure {
            border-radius: 75px; } }
        /* line 48, assets/scss/sections/_climate-news.scss */
        .news-section__slider .slider article figure img {
          width: 100%;
          height: auto; }
          /* line 51, assets/scss/sections/_climate-news.scss */
          .news-section__slider .slider article figure img.badge {
            position: absolute;
            top: 0;
            left: 50%;
            max-width: 80px;
            transform: translateX(-50%); }
            @media only screen and (min-width: 1024px) {
              /* line 51, assets/scss/sections/_climate-news.scss */
              .news-section__slider .slider article figure img.badge {
                max-width: 200px;
                width: auto;
                height: auto; } }
      /* line 65, assets/scss/sections/_climate-news.scss */
      .news-section__slider .slider article p {
        line-height: 1.5;
        text-align: center; }
        @media only screen and (min-width: 768px) {
          /* line 65, assets/scss/sections/_climate-news.scss */
          .news-section__slider .slider article p {
            text-align: unset; } }
        /* line 71, assets/scss/sections/_climate-news.scss */
        .news-section__slider .slider article p .blue {
          color: #47A2D6 !important; }
    /* line 77, assets/scss/sections/_climate-news.scss */
    .news-section__slider .slider .slick-list {
      overflow: visible; }
    /* line 81, assets/scss/sections/_climate-news.scss */
    .news-section__slider .slider .slick-slide {
      transition: all 0.5s; }
    /* line 85, assets/scss/sections/_climate-news.scss */
    .news-section__slider .slider .slick-slide:not(.slick-current) {
      opacity: 0.4; }
    /* line 89, assets/scss/sections/_climate-news.scss */
    .news-section__slider .slider .slick-next,
    .news-section__slider .slider .slick-prev {
      top: 32% !important;
      z-index: 100; }
    /* line 95, assets/scss/sections/_climate-news.scss */
    .news-section__slider .controlls {
      position: absolute;
      display: flex;
      width: calc(100% - 80px);
      margin: 0 40px;
      justify-content: space-between;
      align-items: center;
      top: -210px; }
      /* line 103, assets/scss/sections/_climate-news.scss */
      .news-section__slider .controlls .slick-arrow {
        background-image: url(/wp-content/themes/g1/assets/images/climate/icons/icon-arrow-mobile.svg);
        height: 36px;
        width: 36px;
        transition: all 0.25s ease-in-out;
        z-index: 100;
        cursor: pointer; }
        /* line 110, assets/scss/sections/_climate-news.scss */
        .news-section__slider .controlls .slick-arrow.prev {
          transform: rotate(180deg); }
      /* line 114, assets/scss/sections/_climate-news.scss */
      .news-section__slider .controlls .slide-icon {
        display: block; }
        /* line 116, assets/scss/sections/_climate-news.scss */
        .news-section__slider .controlls .slide-icon img {
          width: 150px;
          height: auto; }
    @media only screen and (min-width: 768px) {
      /* line 124, assets/scss/sections/_climate-news.scss */
      .news-section__slider .controlls {
        position: relative;
        top: 0; }
        /* line 127, assets/scss/sections/_climate-news.scss */
        .news-section__slider .controlls .slick-arrow {
          position: absolute;
          top: 105px;
          height: 13px;
          width: 13px;
          border-color: #DB618D;
          border-style: solid;
          border-width: 0 3px 3px 0;
          transition: all 0.25s ease-in-out;
          margin: 40px 0 0 0;
          z-index: 100;
          cursor: pointer; }
          /* line 139, assets/scss/sections/_climate-news.scss */
          .news-section__slider .controlls .slick-arrow.next {
            right: 25% !important;
            transform: rotate(315deg); }
          /* line 143, assets/scss/sections/_climate-news.scss */
          .news-section__slider .controlls .slick-arrow.prev {
            left: 25% !important;
            transform: rotate(135deg); }
        /* line 148, assets/scss/sections/_climate-news.scss */
        .news-section__slider .controlls .slide-icon {
          display: none; } }

/* line 1, assets/scss/sections/_climate-reports.scss */
.climate-reports {
  background-color: #f3f3f3; }
  /* line 3, assets/scss/sections/_climate-reports.scss */
  .climate-reports__wrapper {
    padding: 40px 20px; }
    @media only screen and (min-width: 1024px) {
      /* line 3, assets/scss/sections/_climate-reports.scss */
      .climate-reports__wrapper {
        max-width: 1082px;
        margin: 0 auto;
        padding: 150px 20px 154px 20px; } }
  /* line 11, assets/scss/sections/_climate-reports.scss */
  .climate-reports__heading {
    margin-bottom: 20px;
    text-align: center;
    font-size: 28px;
    line-height: 42px;
    font-weight: 600; }
    @media only screen and (min-width: 1024px) {
      /* line 11, assets/scss/sections/_climate-reports.scss */
      .climate-reports__heading {
        font-size: 50px;
        line-height: 75px; } }
  /* line 22, assets/scss/sections/_climate-reports.scss */
  .climate-reports__subheading {
    margin-bottom: 40px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400; }
    @media only screen and (min-width: 1024px) {
      /* line 22, assets/scss/sections/_climate-reports.scss */
      .climate-reports__subheading {
        max-width: 948px;
        margin: 0 auto;
        margin-bottom: 5px;
        font-size: 20px;
        line-height: 30px; } }
  /* line 36, assets/scss/sections/_climate-reports.scss */
  .climate-reports__card {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #AAAAAA; }
    /* line 40, assets/scss/sections/_climate-reports.scss */
    .climate-reports__card--title {
      display: flex;
      align-items: center; }
      /* line 43, assets/scss/sections/_climate-reports.scss */
      .climate-reports__card--title h3 {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        margin-left: 15px; }
        @media only screen and (min-width: 768px) {
          /* line 43, assets/scss/sections/_climate-reports.scss */
          .climate-reports__card--title h3 {
            font-size: 20px;
            line-height: 30px; } }
    /* line 54, assets/scss/sections/_climate-reports.scss */
    .climate-reports__card--button {
      margin-left: auto; }
  /* line 58, assets/scss/sections/_climate-reports.scss */
  .climate-reports__filter {
    max-width: 743px;
    display: flex;
    align-items: center; }
    @media only screen and (min-width: 425px) {
      /* line 58, assets/scss/sections/_climate-reports.scss */
      .climate-reports__filter {
        flex-direction: row; } }
  /* line 66, assets/scss/sections/_climate-reports.scss */
  .climate-reports .select-menu {
    max-width: 330px;
    margin: 0px;
    text-align: center; }
    @media only screen and (min-width: 425px) {
      /* line 66, assets/scss/sections/_climate-reports.scss */
      .climate-reports .select-menu {
        margin: 20px;
        text-align: unset; } }
    @media only screen and (min-width: 768px) {
      /* line 66, assets/scss/sections/_climate-reports.scss */
      .climate-reports .select-menu {
        margin: 50px auto; } }
    /* line 77, assets/scss/sections/_climate-reports.scss */
    .climate-reports .select-menu .select-btn {
      display: flex;
      height: 50px;
      background: #fff;
      padding: 20px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      border-radius: 25.5px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 2;
      position: relative; }
      @media only screen and (min-width: 768px) {
        /* line 77, assets/scss/sections/_climate-reports.scss */
        .climate-reports .select-menu .select-btn {
          width: 360px;
          font-size: 16px;
          line-height: 24px; } }
    /* line 97, assets/scss/sections/_climate-reports.scss */
    .climate-reports .select-menu .options {
      display: none;
      position: absolute;
      overflow-y: auto;
      padding: 0;
      margin-top: -30px;
      border-radius: 8px;
      background: #E7E6E6;
      animation-name: fadeInDown;
      -webkit-animation-name: fadeInDown;
      animation-duration: 0.35s;
      animation-fill-mode: both;
      -webkit-animation-duration: 0.35s;
      -webkit-animation-fill-mode: both;
      z-index: 1; }
      @media only screen and (min-width: 425px) {
        /* line 97, assets/scss/sections/_climate-reports.scss */
        .climate-reports .select-menu .options {
          padding: 10px; } }
      @media only screen and (min-width: 768px) {
        /* line 97, assets/scss/sections/_climate-reports.scss */
        .climate-reports .select-menu .options {
          width: 360px;
          font-size: 16px;
          line-height: 24px; } }
      /* line 120, assets/scss/sections/_climate-reports.scss */
      .climate-reports .select-menu .options :first-child {
        margin-top: 20px; }
      /* line 123, assets/scss/sections/_climate-reports.scss */
      .climate-reports .select-menu .options .option {
        display: flex;
        height: 55px;
        cursor: pointer;
        padding: 0 16px;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 18px; }
        @media only screen and (min-width: 768px) {
          /* line 123, assets/scss/sections/_climate-reports.scss */
          .climate-reports .select-menu .options .option {
            font-size: 16px;
            line-height: 24px; } }
  /* line 141, assets/scss/sections/_climate-reports.scss */
  .climate-reports .select-menu.active .options {
    display: block;
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    animation-duration: 0.4s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-fill-mode: both; }
  /* line 152, assets/scss/sections/_climate-reports.scss */
  .climate-reports .select-menu-year {
    max-width: 330px;
    margin: 10px;
    text-align: center; }
    @media only screen and (min-width: 425px) {
      /* line 152, assets/scss/sections/_climate-reports.scss */
      .climate-reports .select-menu-year {
        text-align: unset; } }
    @media only screen and (min-width: 768px) {
      /* line 152, assets/scss/sections/_climate-reports.scss */
      .climate-reports .select-menu-year {
        margin: 50px auto 50px 0px; } }
    /* line 162, assets/scss/sections/_climate-reports.scss */
    .climate-reports .select-menu-year .select-btn-year {
      display: flex;
      height: 50px;
      background: #fff;
      padding: 20px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      border-radius: 25.5px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 2;
      position: relative; }
      @media only screen and (min-width: 768px) {
        /* line 162, assets/scss/sections/_climate-reports.scss */
        .climate-reports .select-menu-year .select-btn-year {
          width: 130px;
          font-size: 16px;
          line-height: 24px; } }
    /* line 182, assets/scss/sections/_climate-reports.scss */
    .climate-reports .select-menu-year .options-year {
      display: none;
      position: absolute;
      overflow-y: auto;
      padding: 10px;
      margin-top: -30px;
      border-radius: 8px;
      background: #E7E6E6;
      animation-name: fadeInDown;
      -webkit-animation-name: fadeInDown;
      animation-duration: 0.35s;
      animation-fill-mode: both;
      -webkit-animation-duration: 0.35s;
      -webkit-animation-fill-mode: both;
      z-index: 1; }
      @media only screen and (min-width: 768px) {
        /* line 182, assets/scss/sections/_climate-reports.scss */
        .climate-reports .select-menu-year .options-year {
          width: 130px;
          font-size: 16px;
          line-height: 24px; } }
      /* line 202, assets/scss/sections/_climate-reports.scss */
      .climate-reports .select-menu-year .options-year :first-child {
        margin-top: 20px; }
      /* line 205, assets/scss/sections/_climate-reports.scss */
      .climate-reports .select-menu-year .options-year .option-year {
        display: flex;
        height: 55px;
        cursor: pointer;
        padding: 0 16px;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 18px; }
        @media only screen and (min-width: 768px) {
          /* line 205, assets/scss/sections/_climate-reports.scss */
          .climate-reports .select-menu-year .options-year .option-year {
            font-size: 16px;
            line-height: 24px; } }
  /* line 223, assets/scss/sections/_climate-reports.scss */
  .climate-reports .select-menu-year.active .options-year {
    display: block;
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    animation-duration: 0.4s;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-fill-mode: both; }
  /* line 234, assets/scss/sections/_climate-reports.scss */
  .climate-reports .empty__info-wrapper {
    display: none; }
  /* line 237, assets/scss/sections/_climate-reports.scss */
  .climate-reports .empty__info-quote {
    display: block;
    text-align: center;
    margin-top: 50px;
    font-size: 18px;
    padding-right: 0; }
    @media only screen and (min-width: 768px) {
      /* line 237, assets/scss/sections/_climate-reports.scss */
      .climate-reports .empty__info-quote {
        font-size: 30px; } }
  /* line 248, assets/scss/sections/_climate-reports.scss */
  .climate-reports .sBtn-text span {
    padding-right: 5px; }
    @media only screen and (min-width: 425px) {
      /* line 248, assets/scss/sections/_climate-reports.scss */
      .climate-reports .sBtn-text span {
        padding-right: 15px; } }
  /* line 256, assets/scss/sections/_climate-reports.scss */
  .climate-reports .sBtn-text-year span {
    padding-right: 3px; }
    @media only screen and (min-width: 425px) {
      /* line 256, assets/scss/sections/_climate-reports.scss */
      .climate-reports .sBtn-text-year span {
        padding-right: 15px; } }

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 30px, 0); }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes fadeInDown {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  to {
    transform: translate3d(0, 20px, 0);
    opacity: 0; } }

/* line 1, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.g1-climate .carbon-footprint-tabs {
  flex-wrap: wrap !important;
  flex-direction: row;
  gap: 20px 40px; }
  @media only screen and (min-width: 768px) {
    /* line 1, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
    .g1-climate .carbon-footprint-tabs {
      gap: 0px 40px; } }
  @media only screen and (min-width: 561px) {
    /* line 9, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
    .g1-climate .carbon-footprint-tabs .chart-circle {
      align-items: center !important; }
      /* line 11, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
      .g1-climate .carbon-footprint-tabs .chart-circle-desc-left {
        flex-direction: row-reverse !important; } }
    @media only screen and (min-width: 561px) and (min-width: 1024px) {
      /* line 11, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
      .g1-climate .carbon-footprint-tabs .chart-circle-desc-left {
        flex-direction: column !important; } }
    @media only screen and (min-width: 561px) and (min-width: 1300px) {
      /* line 11, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
      .g1-climate .carbon-footprint-tabs .chart-circle-desc-left {
        flex-direction: row-reverse !important; } }
  @media only screen and (min-width: 561px) {
        /* line 19, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
        .g1-climate .carbon-footprint-tabs .chart-circle-desc-left .info-tooltip {
          order: 3 !important; } }
      @media only screen and (min-width: 561px) and (min-width: 1024px) {
        /* line 19, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
        .g1-climate .carbon-footprint-tabs .chart-circle-desc-left .info-tooltip {
          order: 1 !important; } }
  @media only screen and (min-width: 561px) {
        /* line 26, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
        .g1-climate .carbon-footprint-tabs .chart-circle-desc-left span:nth-of-type(2) {
          order: 2 !important; } }
      @media only screen and (min-width: 561px) and (min-width: 1024px) {
        /* line 26, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
        .g1-climate .carbon-footprint-tabs .chart-circle-desc-left span:nth-of-type(2) {
          order: 3 !important; } }
  @media only screen and (min-width: 561px) {
        /* line 32, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
        .g1-climate .carbon-footprint-tabs .chart-circle-desc-left span:nth-of-type(3) {
          order: 1 !important; } }
      @media only screen and (min-width: 561px) and (min-width: 1024px) {
        /* line 32, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
        .g1-climate .carbon-footprint-tabs .chart-circle-desc-left span:nth-of-type(3) {
          order: 3 !important; } }
  @media only screen and (max-width: 840px) {
    /* line 44, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
    .g1-climate .carbon-footprint-tabs .chart-circle-picture {
      order: 4 !important;
      min-height: 300px !important; } }
  @media only screen and (max-width: 561px) {
    /* line 51, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
    .g1-climate .carbon-footprint-tabs .chart-circle {
      align-items: center !important; }
      /* line 53, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
      .g1-climate .carbon-footprint-tabs .chart-circle-desc-top, .g1-climate .carbon-footprint-tabs .chart-circle-desc-bottom, .g1-climate .carbon-footprint-tabs .chart-circle-desc-left, .g1-climate .carbon-footprint-tabs .chart-circle-desc-right {
        flex-direction: row !important;
        white-space: nowrap;
        flex-wrap: wrap;
        justify-content: center;
        margin: 10px 0px !important;
        gap: 0px 10px; }
      /* line 64, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
      .g1-climate .carbon-footprint-tabs .chart-circle .info-tooltip {
        order: 2 !important; }
      /* line 68, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
      .g1-climate .carbon-footprint-tabs .chart-circle span:nth-of-type(1) {
        order: 1 !important; }
      /* line 71, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
      .g1-climate .carbon-footprint-tabs .chart-circle span:nth-of-type(2) {
        order: 3 !important; }
      /* line 74, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
      .g1-climate .carbon-footprint-tabs .chart-circle span:nth-of-type(3) {
        order: 4 !important; }
    /* line 79, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
    .g1-climate .carbon-footprint-tabs .chart-bar {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      overflow-y: scroll; }
      /* line 84, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
      .g1-climate .carbon-footprint-tabs .chart-bar-row {
        flex-direction: column-reverse;
        min-height: 350px;
        gap: 10px; }
        /* line 88, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
        .g1-climate .carbon-footprint-tabs .chart-bar-row .city {
          width: auto !important;
          margin-right: 0px !important; }
        /* line 92, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
        .g1-climate .carbon-footprint-tabs .chart-bar-row .bar {
          width: 25px !important; }
          /* line 94, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
          .g1-climate .carbon-footprint-tabs .chart-bar-row .bar-width {
            display: none; }
        /* line 98, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
        .g1-climate .carbon-footprint-tabs .chart-bar-row .percent {
          margin-left: 0px !important; }
        /* line 101, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
        .g1-climate .carbon-footprint-tabs .chart-bar-row .value {
          display: none; } }
  /* line 112, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
  .g1-climate .carbon-footprint-tabs .chart-circle-desc-top::before, .g1-climate .carbon-footprint-tabs .chart-circle-desc-bottom::before, .g1-climate .carbon-footprint-tabs .chart-circle-desc-left::before, .g1-climate .carbon-footprint-tabs .chart-circle-desc-right::before {
    display: none !important; }
  /* line 116, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
  .g1-climate .carbon-footprint-tabs .chart-circle-desc-right {
    right: 0px !important; }
  /* line 119, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
  .g1-climate .carbon-footprint-tabs .chart-circle-desc-left {
    left: 0px !important; }

/* line 124, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.filters-wrapper {
  display: flex;
  gap: 40px; }

/* line 129, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.disabled {
  display: none !important; }

/* line 133, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.year-select {
  position: relative; }

/* line 137, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.year-select select {
  display: none; }

/* line 141, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.select-selected {
  background-color: #fff;
  border-radius: 25px;
  padding: 10px;
  display: flex;
  gap: 10px;
  font-weight: 600; }
  /* line 148, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
  .select-selected:after {
    position: relative;
    display: block;
    content: url("/wp-content/themes/g1/assets/images/arrow_reports.png");
    transition: transform 0.5s; }
  /* line 155, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
  .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    transform: rotate(180deg); }

/* line 162, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.select-items div,
.select-selected {
  color: #000;
  padding: 12px 35px;
  cursor: pointer; }

/* line 169, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.select-selected {
  position: relative;
  z-index: 10; }

/* line 174, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.select-items {
  position: absolute;
  background-color: #fff;
  top: 25px;
  left: 0;
  right: 0;
  z-index: 9; }
  /* line 181, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
  .select-items::before {
    display: block;
    position: relative;
    content: '';
    background-color: #fff;
    width: 100%;
    height: 25px;
    z-index: -10; }

/* line 192, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.select-hide {
  display: none; }

/* line 196, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1); }

/* line 202, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.chart-circle-desc .name {
  color: #fff !important; }

/* line 206, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.chart-circle-desc-top .name {
  background-color: #DB618D; }

/* line 211, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.chart-circle-desc-left .name {
  background-color: #949494; }

/* line 216, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.chart-circle-desc-right .name {
  background-color: #47A2D6; }

/* line 221, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.chart-circle-desc-bottom .name {
  background-color: #000; }

/* line 228, assets/scss/sections/_climate-carbon-footprint-tabs.scss */
.chart-circle-picture img {
  position: absolute;
  max-width: 30%;
  max-height: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

/* line 3, assets/scss/fixes/_climate-mobile-fixes.scss */
.emission .scope-below .scroll-down {
  margin: 0 auto !important; }
  @media only screen and (min-width: 1024px) {
    /* line 3, assets/scss/fixes/_climate-mobile-fixes.scss */
    .emission .scope-below .scroll-down {
      right: 50% !important;
      margin: 20px auto 60px !important; } }

/* line 14, assets/scss/fixes/_climate-mobile-fixes.scss */
.carbon-footprint-inner {
  margin-top: 20px !important; }

/* line 2, assets/scss/_footer.scss */
.main-footer__bottom-nav {
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: flex-start;
  gap: 0 20px;
  color: #333; }
  @media only screen and (min-width: 768px) {
    /* line 2, assets/scss/_footer.scss */
    .main-footer__bottom-nav {
      gap: 0px 40px;
      flex-direction: row; } }

/* line 20, assets/scss/_footer.scss */
.main-footer__bottom {
  display: flex;
  flex-direction: column-reverse; }
  @media only screen and (min-width: 768px) {
    /* line 20, assets/scss/_footer.scss */
    .main-footer__bottom {
      align-items: center;
      flex-direction: row;
      justify-content: flex-end; } }

/* line 31, assets/scss/_footer.scss */
.main-footer__nav-item {
  color: #333; }

/* line 35, assets/scss/_footer.scss */
.main-footer__separator {
  display: none;
  margin-top: 20px; }
  @media only screen and (min-width: 768px) {
    /* line 35, assets/scss/_footer.scss */
    .main-footer__separator {
      display: block; } }
