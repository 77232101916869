.hero-section {
  &__inner {
    h1 {
      a {
        text-decoration: none;
        color: $color-light;
      }
    }
    .info-tooltip {
      margin-top: 20px !important;
      @include mediaQuery(768px) {
        margin-top: 70px !important;
      }
    }
    .tooltip-desc {
      font-size: 20px;
      line-height: 1.5;
    }
    .tooltip-dot {
      bottom: -80px !important;
      @include mediaQuery(768px) {
        bottom: -110px !important;
      }
    }
  }
}