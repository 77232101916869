.main-footer {
    &__bottom-nav {
        list-style: none;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: flex-start;
        gap: 0 20px;
        color: #333;

        @include mediaQuery(768px) {
            gap: 0px 40px;
            flex-direction: row;
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column-reverse;
        
        @include mediaQuery(768px) {
            align-items: center;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    &__nav-item {
        color: #333;
    }

    &__separator {
        display: none;
        margin-top: 20px;

        @include mediaQuery(768px) {
            display: block;
        }
    }
}