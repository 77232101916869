/*!
Theme Name: Groupone - Climate subpage
Theme URI:
Author: 
Author URI: 
Description:
Version: 1.0.0
License:
License URI:
Text Domain: groupone
Tags:
*/

// Helpers
@import 'helpers/mixins';
@import 'helpers/variables';

// Sections
@import 'sections/climate-nav';
@import 'sections/climate-top-hero';
@import 'sections/climate-hero';
@import 'sections/climate-emission';
@import 'sections/climate-carbon-reduce';
@import 'sections/climate-carbon-footprint-over-years';
@import 'sections/climate-news';
@import 'sections/climate-reports';
@import 'sections/climate-carbon-footprint-tabs';

// Fixes
@import 'fixes/climate-mobile-fixes';

// Global
@import 'footer';